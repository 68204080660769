import { withPrefix } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const Footer = () => (
  <footer className="background-footer text-white pt-1">
    <Container>
      <Row>
        <Col md={6} className="py-md-3 pt-3">
          <div
            className="d-flex align-items-center mb-1"
            style={{ height: "80px" }}
          >
            <img
              src={withPrefix("/images/logos/nsf@2x.png")}
              alt="NSF logo"
              width="50"
              className="mr-2"
            />
            <img
              src={withPrefix("/images/logos/nc_state_logo_small.svg")}
              alt="NC State logo"
              style={{ height: "30px" }}
              className="px-2 border-left"
            />
            <img
              src={withPrefix("/images/logos/rti_logo.svg")}
              alt="RTI logo"
              style={{ height: "30px" }}
              className="px-2 border-left"
            />
          </div>
          <p>
            This platform is developed by{" "}
            <a
              href="https://rti.org/"
              target="_blank"
              className="text-white"
              rel="noreferrer"
            >
              RTI International
            </a>{" "}
            in partnership with the project team at the{" "}
            <a
              href="https://www.fi.ncsu.edu/"
              target="_blank"
              className="text-white"
              rel="noreferrer"
            >
              Friday Institute for Educational Innovation
            </a>
            .
          </p>
          <p>
            This project is supported by the National Science Foundation under
            Grant No. DRL 1908760 awarded to North Carolina State University.
            Any opinions, findings, and conclusions or recommendations expressed
            herein are those of the principal investigators and do not
            necessarily reflect the views of the National Science Foundation.
          </p>
          <p>All Rights Reserved © {new Date().getFullYear()} NC State</p>
        </Col>
        <Col md={{ span: 4, offset: 2 }} className="py-0 text-md-right mt-0 mt-md-4">
          <div className="footer-links">
            <p>
              <a href="/pages/terms" className="text-white">
             Privacy Policy and Terms of Use  
              </a>{" "}|{" "}
              <a href={withPrefix("/about")} className="text-white">
                About InSTEP
              </a>
            </p>
          </div>
          <p>
            Contact us at{" "}
            <a
              href="mailto:info@instep-project@ncsu.edu"
              className="text-white"
            >
              instep-project@ncsu.edu
            </a>
            <br />
            <a
              href="https://www.fi.ncsu.edu/projects/instep/"
              className="text-white"
              rel="noreferrer"
            >
              https://www.fi.ncsu.edu/projects/instep
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
