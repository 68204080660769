import { withPrefix } from "gatsby"
import React, { useEffect, useState } from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import { FaArrowRight } from "react-icons/fa"
// import { FaArrowRight } from "react-icons/fa"

function NavBar({ linkTitle, path }) {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/um/users/logged_in")
      const json = await response.json()
      setLoggedIn(json)
    }
    fetchData()
  }, [])

  return (
    <div className="main-nav">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href={withPrefix("/")}>
            {/* <img
              src="/images/logos/InSTEP_logo_nav.svg"
              width="w-100"
              className="align-middle d-none d-lg-block"
              alt="InStep Logo"
            />
            <img
              src="/images/logos/mobile_tablet_logo.svg"
              width="w-100"
              className="align-middle d-block d-lg-none"
              alt="Mobile InStep Logo"
            /> */}
            <img
              src={withPrefix(`/images/logos/nc_logo.svg`)}
              alt="NC State Logo"
              // className="nc-state-logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link
                className="nav-about-btn px-lg-0"
                href={withPrefix(path)}
              >
                {linkTitle}
              </Nav.Link>
              <Nav.Link
                className="nav-signin-btn ml-lg-2"
                href="/um/users/confirmterms"
              >
                Register Now <FaArrowRight style={{ paddingLeft: "5px" }} />
              </Nav.Link>
              {loggedIn ? (
                <Nav.Link
                  className="nav-signin-btn ml-lg-2"
                  href="/usergroups/dashboard"
                >
                  Dashboard <FaArrowRight style={{ paddingLeft: "5px" }} />
                </Nav.Link>
              ) : (
                <Nav.Link className="nav-signin-btn ml-lg-2" href="/login">
                  Log In <FaArrowRight style={{ paddingLeft: "5px" }} />
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavBar
